#invoice {

    &.compact {
        padding: 0;
        overflow: auto;

        .invoice-container {
            padding: 64px;

            .card {
                width: 1020px;
                min-width: 1020px;
                max-width: 1020px;
                padding: 64px 88px;
                overflow: hidden;
                background: #FFFFFF;

                .header {

                    .invoice-date {
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.54);
                        margin-bottom: 32px;
                    }

                    .client {

                        .invoice-number {
                            font-size: 18px;
                            padding-bottom: 2px;

                            .title {
                                color: rgba(0, 0, 0, 0.54);
                            }

                            .number {
                                padding-left: 6px;
                            }
                        }

                        .due-date {
                            font-size: 18px;
                            padding-bottom: 16px;

                            .title {
                                color: rgba(0, 0, 0, 0.54);
                            }

                            .date {
                                padding-left: 6px;
                            }
                        }

                        .info {
                            color: rgba(0, 0, 0, 0.54);
                            line-height: 22px;
                        }
                    }

                    .issuer {
                        margin-right: -88px;
                        padding-right: 66px;

                        .logo {
                            width: 96px;
                            height: 96px;
                            font-size: 72px;
                            border-right: 1px solid rgba(255, 255, 255, 0.7);
                        }

                        .info {
                            padding: 16px;
                        }
                    }
                }

                .content {

                    .invoice-table {
                        margin-top: 64px;
                        font-size: 15px;

                        thead {

                            tr {

                                th {

                                    &:first-child {
                                        padding-left: 8px;
                                    }

                                    &:last-child {
                                        padding-right: 8px;
                                    }
                                }
                            }
                        }

                        tbody {

                            tr {

                                td {

                                    &:first-child {
                                        padding-left: 8px;
                                    }

                                    &:last-child {
                                        padding-right: 8px;
                                    }
                                }
                            }
                        }

                        .title {
                            font-size: 16px;
                        }

                        .detail {
                            margin-top: 8px;
                            font-size: 12px;
                            color: rgba(0, 0, 0, 0.54);
                            max-width: 360px;
                        }
                    }

                    .invoice-table-footer {
                        margin: 32px 0 72px 0;

                        tr {

                            td {
                                text-align: right;
                                font-size: 16px;
                                font-weight: 500;
                                color: rgba(0, 0, 0, 0.54);
                                border-bottom: none;
                                padding: 4px 8px;

                                &:first-child {
                                    text-align: left;
                                }
                            }

                            &.discount {

                                td {
                                    padding-bottom: 32px;
                                }
                            }

                            &.total {

                                td {
                                    padding: 24px 8px;
                                    border-top: 1px solid rgba(0, 0, 0, 0.12);
                                    font-size: 35px;
                                    font-weight: 300;
                                    color: rgba(0, 0, 0, 1);
                                }
                            }
                        }
                    }
                }

                .footer {

                    .note {
                        font-size: 15px;
                        font-weight: 500;
                        margin-bottom: 24px;
                    }

                    // IE10 fix
                    .logo, .small-note {
                        -ms-flex: 0 1 auto;
                    }

                    .logo {
                        width: 32px;
                        min-width: 32px;
                        height: 32px;
                        font-size: 17px;
                        font-weight: 500;
                        margin-right: 24px;
                        border-radius: 2px;
                        overflow: hidden;
                    }

                    .small-note {
                        font-size: 12px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.54);
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

/* PRINT STYLES */
@media print {

    /* Invoice Specific Styles */
    #invoice {

        &.compact {

            .invoice-container {
                padding: 0;

                .card {
                    width: 100%;
                    min-width: 0;
                    background: none;
                    padding: 0;
                    box-shadow: none;

                    .header {

                        .invoice-date {
                            margin-bottom: 16pt;
                        }

                        .issuer {
                            padding-right: 0;
                            margin-right: 0;
                        }
                    }

                    .content {

                        .invoice-table {
                            margin-top: 16pt;

                            thead {

                                tr {

                                    th {
                                        font-size: 10pt;
                                        max-width: 60pt;

                                        &:first-child {
                                            padding-left: 0;
                                        }

                                        &:last-child {
                                            padding-right: 0;
                                        }
                                    }
                                }
                            }

                            tbody {

                                tr {

                                    td {

                                        &:first-child {
                                            padding-left: 0;
                                        }

                                        &:last-child {
                                            padding-right: 0;
                                        }
                                    }
                                }
                            }

                            .title {
                                font-size: 10pt;
                            }

                            .detail {
                                margin-top: 4pt;
                                font-size: 9pt;
                                max-width: none;
                            }
                        }

                        .invoice-table-footer {
                            margin: 16pt 0;

                            tr {

                                td {
                                    font-size: 13pt;
                                    padding: 4pt 4pt;

                                    &:first-child {
                                        text-align: left;
                                        padding-left: 0;
                                    }

                                    &:last-child {
                                        padding-right: 0;
                                    }
                                }

                                &.discount {

                                    td {
                                        padding-bottom: 16pt;
                                    }
                                }

                                &.total {

                                    td {
                                        padding: 16pt 4pt 0 4pt;
                                        font-size: 16pt;

                                        &:first-child {
                                            padding-left: 0;
                                        }

                                        &:last-child {
                                            padding-right: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .footer {

                        .note {
                            font-size: 10pt;
                            margin-bottom: 8pt;
                        }

                        .logo {
                            font-size: 14pt;
                            margin-right: 8pt;
                        }

                        .small-note {
                            font-size: 8pt;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
}