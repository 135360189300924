#profile {

    .timeline-tab {
        max-width: 1200px;

        .timeline {
            padding: 8px;

            .add-post {
                margin-bottom: 0;

                textarea {
                    font-size: 13px;
                    width: 100%;
                    height: 140px;
                    border: none;
                    padding: 16px;
                    resize: vertical;
                }

                footer {
                    background: #F3F4F5;

                    .post-button {
                        margin: 0;
                        width: 64px;
                        min-width: 64px;
                        height: 30px;
                        line-height: 30px;
                        min-height: 30px;
                    }
                }
            }

            md-divider {
                margin: 32px 0;
            }

            .timeline-item {
                margin-bottom: 32px;
                overflow: hidden;
                border-radius: 2px;
                background: #FFFFFF;

                &:last-child {
                    margin-bottom: 0;
                }

                header {
                    padding: 16px 0 8px 16px;

                    .title {
                        font-weight: 500;

                        .username {
                            margin-right: 4px;
                        }
                    }

                    .time {
                        color: rgba(0, 0, 0, 0.54);
                    }
                }

                .content {

                    .message {
                        padding: 8px 16px;
                    }

                    .media {
                        padding: 16px 8px;

                        img, iframe {
                            width: 100%;
                        }

                        a {
                            color: inherit;
                        }
                    }

                    .like-button,
                    .share-button {
                        padding: 4px 6px;
                        text-transform: inherit;
                        font-size: 13px;
                        font-weight: normal;
                        margin: 0 0 16px 8px;
                        min-width: inherit;
                        line-height: inherit;

                        &:hover {
                            background-color: transparent;
                        }

                        md-icon {
                            margin: 0 8px 0 0;
                        }
                    }

                    .article {
                        border: 1px solid rgba(0, 0, 0, 0.12);
                        margin: 8px;

                        .media {
                            padding: 0;
                            overflow: hidden;
                            border-bottom: 1px solid rgba(0, 0, 0, 0.12);

                            img {
                                display: block;
                                padding: 0;
                            }

                        }

                        .title {
                            font-size: 15px;
                            padding: 16px 16px 4px 16px;
                        }

                        .subtitle {
                            padding: 0 16px;
                            color: rgba(0, 0, 0, 0.54);
                        }

                        .excerpt {
                            padding: 16px 16px;
                        }
                    }
                }

                footer {
                    border-top: 1px solid rgba(0, 0, 0, 0.08);
                    background-color: rgba(0, 0, 0, 0.04);
                    padding: 16px;

                    .comment-count {
                        margin-bottom: 16px;
                        cursor: pointer;

                        md-icon {
                            margin-left: 8px;
                        }
                    }

                    .comment {
                        margin-bottom: 24px;

                        .username {
                            font-weight: 500;
                            margin-right: 4px;
                        }

                        .message {
                            color: rgba(0, 0, 0, 0.87);
                        }

                        .time {
                            color: rgba(0, 0, 0, 0.54);
                        }

                        .actions {
                            margin-top: 8px;

                            .reply-button {
                                margin-right: 16px;
                                cursor: pointer;
                            }

                            .report-button {
                                margin: 0;
                                cursor: pointer;
                            }
                        }
                    }

                    .reply {

                        form {

                            textarea {
                                width: 100% !important;
                                min-height: 72px;
                                padding: 8px;
                                margin-bottom: 8px;
                                font-size: 13px;
                                border: 1px solid rgba(0, 0, 0, 0.12);
                            }

                            .post-comment-button {
                                margin: 0;
                                text-transform: inherit;
                                font-weight: normal;
                                padding: 0 12px;
                                min-height: 30px;
                                min-width: inherit;
                                line-height: 30px;
                            }
                        }
                    }
                }
            }
        }

        .timeline-sidebar {
            padding: 8px 8px 8px 32px;

            .latest-activity {

                .content {

                    .activities {

                        .activity {
                            padding: 16px 0;

                            .avatar {
                                margin-right: 16px;
                            }

                            .username {
                                font-weight: 500;
                            }

                            .message {
                                font-weight: 500;
                            }

                            .time {
                            }
                        }
                    }
                }
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    #profile {

        .timeline-tab {

            .timeline-sidebar {
                padding: 8px
            }
        }
    }

}