#notes {

    > md-content {

        .notes {

            .note {
                padding: 8px;

                md-card {
                    margin: 0;
                    cursor: pointer;

                    img {
                    }

                    md-card-content {
                        padding: 0;

                        > *:first-child {
                            padding-top: 16px !important;
                        }

                        > *:last-child {
                            padding-bottom: 16px !important;
                        }

                        md-card-title {
                            padding: 8px 16px;

                            md-card-title-text {
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 21px;
                            }

                        }

                        md-description {
                            display: block;
                            padding: 8px 16px;

                            > span {
                                white-space: pre-wrap;
                                word-wrap: break-word;
                            }

                            &[font-size='1'] {
                                font-size: 14px;
                                line-height: 19px;
                                font-weight: 400;
                            }

                            &[font-size='2'] {
                                font-size: 18px;
                                line-height: 28px;
                                font-weight: 300;
                            }

                            &[font-size='3'] {
                                font-size: 24px;
                                line-height: 36px;
                                font-weight: 300;
                            }

                            &[font-size='4'] {
                                font-size: 36px;
                                line-height: 48px;
                                font-weight: 100;
                            }

                            &[font-size='5'] {
                                font-size: 48px;
                                line-height: 60px;
                                font-weight: 100;
                            }

                        }

                        .checklist {
                            padding: 8px 16px;

                            .checklist-item {
                                padding: 8px 0;

                                md-checkbox {
                                    margin: 0;
                                }

                            }
                        }

                        .labels {
                            padding: 8px 16px;

                            .label {
                                background: rgba(0, 0, 0, 0.1);
                                margin: 0 0 2px 4px;
                                padding: 2px 5px;
                                font-size: 11px;
                                white-space: nowrap;
                                border-radius: 2px;
                            }

                        }

                        .reminder {
                            padding: 8px 16px;
                        }
                    }
                }
            }
        }
    }
}